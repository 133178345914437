import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import Layout from '../components/layout'
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SEO from "../components/SEO"

const SubjectTemplate = ({ data }) => {
  const {
    title,
    slug,
    summary: { summary },
    mainText,
    mainImage,
  } = data.subject
  const articles = data.articles.edges
  // console.log("data:", data)
  // console.log("articles:", articles)
  const Bold = ({ children }) => <strong>{children}</strong>
  const Text = ({ children }) => <p>{children}</p>
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    },
  }

  return (
    <Layout>
      <SEO title={title} description={summary} />
      <>
        <div>
          <h1>{title}</h1>
        </div>
        <div>
          {mainImage ?
            <Image fluid={mainImage.fluid} className="mainImg" alt={title} />
            : null
          }
          {documentToReactComponents(mainText.json, options)}
        </div>
        <div className="clear" />
        {
          articles ?
            articles.map(({ node }) => {
              return (
                <div key={node.slug} className="linkBlock">
                  {node.mainImage ?
                    <Link to={`/s/${slug}/${node.slug}`}>
                      <Image fluid={node.mainImage.fluid} className="mainImgPic" alt={node.title} />
                    </Link>
                    : null
                  }
                  <h2><Link to={`/s/${slug}/${node.slug}`}>{node.title}</Link></h2>
                  <p>{node.summary.summary}</p>
                  <div className="clear" />
                </div>
              )
            })
            : null
        }
      </>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String, $title: String) {
    subject: contentfulSubject(slug: { eq: $slug }) {
      title
      slug
      summary {
        summary
      }
      mainText {
        json
      }
      mainImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    articles: allContentfulArticle(
        filter: { subject: {title: {eq: $title}}, nn: { ne: null } },
        sort: { fields: nn, order: ASC }
      ) {
      totalCount
      edges {
        node {
          title
          slug
          summary {
            summary
          }
          mainImage {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default SubjectTemplate
